import React from 'react';

const ContactInfo = () => (
  <div className='bg-white'>
    <div className='container mx-auto py-16 px-8'>

      <h2 className='font-sans font-semibold text-blue text-xl lg:text-2xl mb-4 leading-tight'>
        Questions about the NL Genome Project?
      </h2>

      <div className='flex'>
        <div className='flex-1 mr-8'>
          <a href='tel:+17098002748' className='font-soft font-semibold text-black text-lg'>
            709.800.2748
          </a>

          <p className='font-soft font-semibold text-black text-lg mb-4'>
            <a className='underline text-blue' href='mailto:hi@nlgenomeproject.ca'>
              hi@nlgenomeproject.ca
            </a>
          </p>

          <p className='font-sans text-grey leading-snug'>
            Sequence Bio
          </p>

          <p className='font-sans text-grey leading-snug'>
            100 New Gower Street, Suite 370
            <br />
            St. John's, NL, A1C 6K3, Canada
          </p>
        </div>
      </div>
    </div>
  </div>
);

export default ContactInfo;
