import React from 'react';
import Layout from '../components/layout';
import SEO from '../components/seo';
import Hero from '../components/404/hero';
import ContactInfo from '../components/contact-us/contact-info';

const NotFoundPage = () => (
  <Layout>
    <SEO title='404: Not found'/>
    <Hero />
    <ContactInfo />
  </Layout>
);

export default NotFoundPage;